import React from "react";
import { Link } from "gatsby";
import * as styles from "./feedItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faInstagram, faFacebookF, faTiktok, faSnapchatGhost, faLinkedinIn, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { faEllipsisV, faEnvelope, faHeartBroken } from "@fortawesome/free-solid-svg-icons";
import DropdownMenu from "./dropdownMenu";
import DisplayName from "./displayname";
import Common from "../config/common";
import util from "../config/util";

export default class Button extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    onDoSomething = () => {};

    renderMedia = (item, index) => {
        return (
            <Link key={`feed_item_${index}`} className={styles.media_container} to={`/m/${item.mediaId}`}>
                {item.mediaType === "AUDIO" ? (
                    <div>
                        <audio controls controlsList="nodownload" loop={true} preload="true" src={`${process.env.GATSBY_STORAGE}media/${item.mediaData}`}>
                            Your browser does not support the
                            <code>audio</code> element.
                        </audio>
                    </div>
                ) : item.mediaType === "VIDEO" ? (
                    <video controls autoPlay={true} controlsList="nodownload nofullscreen noremoteplayback noplaybackrate" loop={true} preload="true">
                        <source src={`${process.env.GATSBY_STORAGE}media/${item.mediaData}`} type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                ) : (
                    <img src={`${process.env.GATSBY_STORAGE}media/${item.mediaData}`} />
                )}
            </Link>
        );
    };

    onUnfollowUser = () => {
        Api.market({
            endpoint: "/user/follow",
            method: "PATCH",
            data: {
                displayName: this.props.displayName,
                state: "UNFOLLOWED",
            },
        })
            .then(() => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
                util.notify.error("Failed to unfollow this user, please try again later.");
            });
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.inner_container}>
                    <div className={styles.user_container}>
                        <div className={styles.avatar_container}>
                            <div className={styles.avatar_outer}>
                                <img src={`${process.env.GATSBY_STORAGE}user/${this.props.profileImage}`} />
                            </div>
                        </div>
                        <div className={styles.user_detail_container}>
                            <h4>
                                <DisplayName>{this.props.displayName}</DisplayName>
                            </h4>
                            <h6 title={Common.dateString(this.props.timestamp)}>{Common.dateAgo(this.props.timestamp)}</h6>
                        </div>
                        <div className={styles.more_container}>
                            {/* <DropdownMenu
                                icon={faEllipsisV}
                                pin={"right"}
                                items={[
                                    {
                                        type: "item",
                                        name: "Share on Facebook",
                                        icon: faFacebookF,
                                        onClick: this.onDoSomething,
                                    },
                                    {
                                        type: "item",
                                        name: "Share on Twitter",
                                        icon: faTwitter,
                                        onClick: this.onDoSomething,
                                    },
                                    {
                                        type: "item",
                                        icon: faEnvelope,
                                        name: "Share via Email",
                                        onClick: this.onDoSomething,
                                    },
                                    ...(this.props.showUnfollowLink === true
                                        ? [
                                              {
                                                  type: "item",
                                                  icon: faHeartBroken,
                                                  name: "Unfollow User",
                                                  onClick: this.onUnfollowUser,
                                              },
                                          ]
                                        : []),
                                    {
                                        type: "item",
                                        name: "Report",
                                        //icon:faFlag,
                                        description: "Report this page for some wrong-doing.",
                                        onClick: this.onDoSomething,
                                    },
                                ]}
                            /> */}
                        </div>
                    </div>
                    <div className={styles.content_container}>
                        {this.props.text && (
                            <div className={styles.content_text}>
                                <p>{this.props.text.en}</p>
                            </div>
                        )}
                        {this.props.content.length === 1
                            ? this.renderMedia(this.props.content[0], 0)
                            : this.props.content.length > 1 && <div className={styles.media_scroller}>{this.props.content.map(this.renderMedia)}</div>}
                    </div>
                </div>
            </div>
        );
    }
}
