import React from "react";
import { withAuthentication } from "../../hoc/withAuthentication";
import InfiniteScroll from "react-infinite-scroll-component";
import Main from "../../components/main";
import FeedItem from "../../components/feedItem";
import ContentArea from "../../components/contentArea";
import MissingDataPanel from "../../components/missingDataPanel";
import Button from "../../components/button";
import MasonryTiles from "../../components/masonryTiles";
import Consts from "../../config/consts";
import Common from "../../config/common";
import Api from "../../config/api";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED], // INDEX MUST HAVE BOTH AUTH AND UNAUTH IN ORDER TO AVOID FAILURE LOOPING
        redirectPage: "/",
    },
    class Feed extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();

            this.state = {
                initialising: true,
                windowSize: window.innerWidth,
                fetching: false,
                fetchHasMore: true,
                items: [],
            };
        }

        componentDidMount() {
            let resize_timeout = null;
            window.onresize = (val) => {
                clearTimeout(resize_timeout);
                resize_timeout = setTimeout(() => {
                    this.setState({
                        windowSize: window.innerWidth,
                    });
                }, 200);
            };

            setTimeout(() => {
                this.fetchMore();
            }, 100);
        }

        fetchMore = () => {
            return new Promise((resolve, reject) => {
                const fetch_quantity = 40;
                if (!this.state.fetching && this.state.fetchHasMore) {
                    this.setState(
                        {
                            fetching: true,
                        },
                        () => {
                            Api.market({
                                endpoint: "/user/feed",
                                method: "GET",
                                data: {
                                    startIndex: this.state.items.length,
                                    quantity: fetch_quantity,
                                },
                            })
                                .then((results) => {
                                    this.setState({
                                        initialising: false,
                                        items: [...this.state.items, ...results.feed],
                                        fetching: false,
                                        fetchHasMore: results.feed.length === fetch_quantity,
                                    });
                                })
                                .catch((e) => {
                                    this.setState({
                                        initialising: false,
                                        fetching: false,
                                        fetchHasMore: true,
                                    });
                                });
                        }
                    );
                }
            });
        };

        onShowBidOverlay = (listing) => {
            if (this.main_ref) {
                this.main_ref.current.showBidOverlay(listing);
            }
        };

        render() {
            return (
                <Main
                    ref={this.main_ref}
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea slim={true} header={"Your Feed"} bottomRule={this.state.items.length <= 0}>
                        {this.state.items.length <= 0 ? (
                            <MissingDataPanel
                                //emoji={<span>&#129335;</span>}
                                title={"Nothing to Display!"}
                                message={"We don't currently have any feed items to display! Come back here when you've favourited a few creators or creations!"}
                                // button={<Button displayMode={1} hoverMode={6} to={"/explore"} text={"Explore Creations"} />}
                            />
                        ) : (
                            <InfiniteScroll
                                dataLength={this.state.items.length} //This is important field to render the next data
                                next={this.fetchMore}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    width: "100%",
                                }}
                                hasMore={this.state.fetchHasMore}
                                loader={
                                    <p style={{ textAlign: "center", padding: 50 }}>
                                        <b>Loading...</b>
                                    </p>
                                }
                                endMessage={
                                    <p style={{ textAlign: "center", padding: 50 }}>
                                        <b>No more items &#128532;</b>
                                    </p>
                                }
                            >
                                {this.state.items.map((item, index, arr) => (
                                    <FeedItem
                                        key={`feed_item_${index}`}
                                        displayName={item.displayName}
                                        profileImage={item.profileImage}
                                        type={item.type}
                                        timestamp={item.createdAt}
                                        content={item.content}
                                        text={item.description}
                                        showUnfollowLink={Common.isDisplayNameMine(this.props.auth.user, item.displayName)}
                                    />
                                ))}
                            </InfiniteScroll>
                        )}
                    </ContentArea>
                    {this.state.items.length <= 0 && (
                        <ContentArea slim={true} header={"Trending"}>
                            <MasonryTiles
                                contentEndpoint={"/content/trending"}
                                fetchQuantity={12}
                                windowSize={this.state.windowSize}
                                showTrending={true}
                                showLoadMore={false}
                                onBid={this.onShowBidOverlay}
                            />
                        </ContentArea>
                    )}
                </Main>
            );
        }
    }
);
