// extracted by mini-css-extract-plugin
export var avatar_container = "feedItem-module--avatar_container--lIkb9";
export var avatar_outer = "feedItem-module--avatar_outer--AfScu";
export var container = "feedItem-module--container--ThAQD";
export var content_container = "feedItem-module--content_container--XSWb+";
export var content_text = "feedItem-module--content_text--YITK2";
export var inner_container = "feedItem-module--inner_container--Za+Ll";
export var media_container = "feedItem-module--media_container--EbrmS";
export var media_scroller = "feedItem-module--media_scroller--NrVh3";
export var more_container = "feedItem-module--more_container--a13KQ";
export var user_container = "feedItem-module--user_container--PMzch";
export var user_detail_container = "feedItem-module--user_detail_container--zY-zZ";